import React from 'react'
import styled from 'styled-components'

const BasicCard = (props) => {
  const {
    children,
    background,
    border,
    borderRadius,
    maxWidth,
    padding,
    margin,
    boxShadow,
  } = props
  return (
    <BasicCardWrapper
      background={background}
      border={border}
      borderRadius={borderRadius}
      maxWidth={maxWidth}
      padding={padding}
      margin={margin}
      boxShadow={boxShadow}
    >
      {children}
    </BasicCardWrapper>
  )
}

const BasicCardWrapper = styled.div`
  position: relative;
  max-width: ${(props) => props.maxWidth || '960px'};
  margin: ${(props) => props.margin || '40px auto'};
  border: ${(props) => props.border || '1px solid #d4dbe0'};
  border-radius: ${(props) => props.borderRadius || '20px'};
  background: ${(props) => props.background || 'var(--white)'};
  padding: ${(props) => props.padding || '20px'};
  box-shadow: ${(props) => props.boxShadow || '0 2px 8px 0 rgb(0 0 0 / 8%)'};
`

export default BasicCard
